import React, { useContext, useEffect, useRef, useState } from 'react';
// import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from './shared-functions.js';
import { Row, Col, Navbar, Nav, Image, Card, Button } from 'react-bootstrap';
import Footer from './components/common/Footer.js';
import { CloudFog, Database, Pencil, ThumbsUp, ListMagnifyingGlass, Clock, Funnel, Lock, Sparkle, UsersFour, ArrowUp, ArticleNyTimes, BoundingBox, Export, Cube } from "@phosphor-icons/react";
import Logo from "./components/common/Logo.js";
import { PopupButton } from '@typeform/embed-react';
import { ThemeContext } from "./Theme.js";
import { BILLING_PLANS, CARDS } from './config.js';
// import Marquee from "react-fast-marquee";
import { Helmet } from "react-helmet";

export default function Home() {

  // const navigate = useNavigate();
  // const location = useLocation();
  const { theme } = useContext(ThemeContext);

  const benefitsRef = useRef(null);
  const platformRef = useRef(null);
  const pricingRef = useRef(null);

  const [item, setItem] = useState(CARDS[0]);

  let isPageWide = useMediaQuery('(min-width: 640px)');


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [0]);

  const scrollEffect = (targetRef) => {
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    CARDS.forEach((card, i) => setTimeout(
      () => setItem(card),
      (i + 1) * 1000, // 5000, 10000, 15000
    ));
  }, []);

  return (
    <Row style={{ justifyContent: 'center', width: '100%', height: '95vh', marginLeft: 0, marginRight: 0 }}>
      <Helmet>
        <script
          src="https://getlaunchlist.com/js/widget.js"
          type="text/javascript"
          defer
        />
      </Helmet>
      <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0 }}>
        <Navbar justify="true" fixed="top" variant={theme === 'light-theme' ? "light" : "dark"} style={{ padding: '5px 20px', backgroundColor: '#202124' }}>
          <Logo width={120} />
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link onClick={() => scrollEffect(benefitsRef)}>Benefits</Nav.Link>
              {isPageWide && <Nav.Link onClick={() => scrollEffect(platformRef)}>Platform</Nav.Link>}
              <Nav.Link onClick={() => scrollEffect(pricingRef)}>Pricing</Nav.Link>
              {/* {isPageWide && <Nav.Link><PopupButton id="DIwxC8Cf" className="btn btn-link" style={{ marginTop: -2 }}>Join waitlist</PopupButton></Nav.Link>} */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

        {/* , backgroundImage: `url('/assets/frontpage/waves.png')`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' */}

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, paddingBottom: 0 }}>
          <Col xs={12} sm={12} md={11} lg={10} xl={10} style={{ padding: isPageWide ? 20 : 0, maxWidth: 1200, textAlign: 'center' }}>
            <h6>Your research & writing assistant</h6>
            {/* <h1 style={{ marginTop: isPageWide ? 0 : 0 }}>Your AI-powered content writing assistant</h1> */}
            {/* <h1 style={{ marginTop: isPageWide ? 0 : 0 }}>Craft exceptional content</h1> */}
            {/* <h1 style={{ marginTop: isPageWide ? 0 : 0 }}>AI agents for research-driven writing</h1> */}
            {/* <h1 style={{ marginTop: isPageWide ? 0 : 0 }}>Craft <span className='gradient-text'>{item.plural}</span><br />using AI agents</h1> */}
            {/* <h1 style={{ marginTop: isPageWide ? 0 : 0 }}>Streamline content creation with AI-powered research agents</h1> */}
            {/* <h1 style={{ marginTop: isPageWide ? 0 : 0 }}>Create except<span className='gradient-text'>{item.plural}</span><br />using AI agents</h1> */}
            <h1 style={{ marginTop: isPageWide ? 0 : 0 }}>Transform your ideas into exceptional marketing content within seconds</h1>
            
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: isPageWide ? 10 : 0 }}>
              <Col xs={11} sm={9} md={8} lg={8} xl={6} style={{ padding: 10 }}>
                <p className='large muted'>Terastack's AI-powered research assistants search, extract and synthesize data from diverse sources, including your own documents, to create insightful, engaging content based on your ideas and thoughts.</p>
                <PopupButton id="DIwxC8Cf" className="btn btn-primary" style={{ marginTop: isPageWide ? 30 : 20 }}>
                  Get early access
                </PopupButton>
                {/* <div class="launchlist-widget" data-key-id="7dUfaA" data-height="160px" style={{ marginTop: 10 }}></div> */}
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0, paddingBottom: 0 }}>
          <Marquee speed={50} pauseOnHover gradient gradientColor={'var(--bg-primary)'} gradientWidth={100}>
            {CARDS.map((c, id) => {
              return (
                <Card key={id} className='card-pattern' style={{ display: 'flex', backgroundColor: 'transparent', height: 200, width: 160, boxShadow: '3px 3px 0px var(--separator)', marginRight: 20 }}>
                  <Row style={{ flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', marginLeft: 0, marginRight: 0, textAlign: 'center' }}>
                    <h4>{c.name}</h4>
                    {c.icon}
                  </Row>
                </Card>
              )
            })}
          </Marquee>
        </Row> */}

        {/* Works with all major LLMs */}
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <Col xs={8} sm={6} md={5} lg={4} xl={3} style={{ textAlign: 'center' }}>
            <p className='muted' style={{ textAlign: 'center' }}>Works seamlessly with your choice of open or closed-source LLMs</p>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 20, paddingBottom: 0 }}>
          <Image src={'/assets/icons/openai.png'} style={{ width: 24, objectFit: 'cover', marginRight: 12, marginLeft: 12 }} />
          <Image src={'/assets/icons/llama.png'} style={{ width: 30, objectFit: 'cover', marginRight: 12, marginLeft: 12 }} />
          <Image src={'/assets/icons/gemini.png'} style={{ width: 30, objectFit: 'cover', marginRight: 12, marginLeft: 12 }} />
          <Image src={'/assets/icons/anthropic.png'} style={{ width: 24, objectFit: 'cover', marginRight: 14, marginLeft: 14 }} />
          <Image src={'/assets/icons/mistral.png'} style={{ width: 22, objectFit: 'cover', marginRight: 16, marginLeft: 16 }} />
          <Image src={'/assets/icons/cohere.png'} style={{ width: 24, objectFit: 'cover', marginRight: 16, marginLeft: 16 }} />
        </Row>

        {/* Screenshot - Terastack sources */}
        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 60 }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 0, textAlign: 'center', width: '100%' }}>
            <Image src={isPageWide ? '/assets/frontpage/hero2.png' : '/assets/frontpage/hero-mobile.png'} style={{ width: '100%' }} />
          </Col>
        </Row> */}

        {/* Key Benefits - Answers instead of blue links, Write content, Citations, Multiple sources */}

        <Row ref={benefitsRef} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, paddingTop: 60 }}>
          <Col xs={11} sm={11} md={11} lg={11} xl={10} style={{ padding: isPageWide ? 20 : 10, textAlign: 'center', maxWidth: 1200 }}>
            <h6 className='muted'>Key Benefits</h6>
            <h2 style={{ marginTop: 10 }}>Purpose-built for research-driven content creation</h2>
          </Col>
        </Row>

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
          <Col xs={12} sm={12} md={10} lg={9} xl={7} style={{ padding: 0 }}>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 260 : 200 }}>
                  <Clock size={24} color={'var(--accent)'} />
                  <h4 style={{ marginTop: 20 }}>Save hours</h4>
                  <p className='muted'>Pull citable answers to research questions - not a wall of blue links that take up hours of your time clicking, reading and extracting data from.</p>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 260 : 200 }}>
                  <Funnel size={24} color={'var(--accent)'} />
                  <h4 style={{ marginTop: 20 }}>Query multiple sources</h4>
                  <p className='muted'>Look up multiple knowledge sources including PubMed, ArXiv, news outlets, websites, images, and your personal document library, all in one place, and in seconds.</p>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 260 : 200 }}>
                  <ThumbsUp size={24} color={'var(--accent)'} />
                  <h4 style={{ marginTop: 20 }}>Write trustworthy content</h4>
                  <p className='muted'>Terastack's AI agents pull in data from trustworthy sources, along with citations, that help you in turn build trust with your audience.</p>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 260 : 200 }}>
                  <CloudFog size={24} color={'var(--accent)'} />
                  <h4 style={{ marginTop: 20 }}>Avoid hallucinations</h4>
                  <p className='muted'>Terastack's AI agents are instructed to use only your chosen knowledge sources ensuring the answers generated are grounded in data and facts - not a hallucination that plagues most AI tools.</p>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 260 : 200 }}>
                  <Pencil size={24} color={'var(--accent)'} />
                  <h4 style={{ marginTop: 20 }}>Avoid writer's block</h4>
                  <p className='muted'>Use Terastack's AI agents to transform a simple idea or even a set of keywords into an outline, article, essay, or blog post with data and facts from trusted sources.</p>
                </Card>
              </Col>
              {/* <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 260 : 200 }}>
                  <Users size={24} color={'var(--accent)'} />
                  <h4 style={{ marginTop: 20 }}>Seamlessly collaborate</h4>
                  <p className='muted'>Writing is often a team effort, whether you are co-authoring or seeking reviews and edits. With Terastack, you can keep your document private or seamlessly share it with a team member when needed.</p>
                </Card>
              </Col> */}
              <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 260 : 200 }}>
                  <UsersFour size={24} color={'var(--accent)'} />
                  <h4 style={{ marginTop: 20 }}>Easily collaborate</h4>
                  <p className='muted'>Upload, import and internally share research documents in most common file types, so you can make sure everyone on your team can leverage a single, convenient knowledge source.</p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Logos */}

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, paddingTop: 80, marginTop: 0 }}>
          <Col xs={9} sm={7} md={6} lg={5} xl={3} style={{ textAlign: 'center' }}>
            <p style={{ textAlign: 'center' }}>Created by a team with experience in academia and science-based technology companies</p>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, paddingTop: 40, paddingBottom: 0 }}>
          <Image src={'/assets/logos/ucsb.png'} style={{ width: 65, marginTop: 10, marginLeft: 12, marginRight: 12 }} />
          <Image src={'/assets/logos/yahoo.png'} style={{ width: 140, marginLeft: 12, marginRight: 12 }} />
          <Image src={'/assets/logos/onecelsius.png'} style={{ width: 160, marginLeft: 12, marginRight: 12 }} />
          <Image src={'/assets/logos/ubersense.png'} style={{ width: 160, marginLeft: 12, marginRight: 12 }} />
          <Image src={'/assets/logos/florohealth.png'} style={{ width: 140, marginLeft: 12, marginRight: 12 }} />
        </Row>

        {/* Platform: Multiple public sources, Query docs, Content, Citations, Models */}

        <Row ref={platformRef} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginTop: 40, marginRight: 0, paddingTop: 60 }}>
          <Col xs={11} sm={11} md={6} lg={6} xl={5} style={{ padding: isPageWide ? 20 : 10, textAlign: 'center', maxWidth: 900 }}>
            <h6 className='muted'>The Terastack App</h6>
            <h2 style={{ marginTop: 10 }}>Essential tools for research-driven content creation</h2>
          </Col>
        </Row>

        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 40 }}>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <ArticleNyTimes size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Rich-text editor</h4>
              <p className='muted' style={{ marginTop: 0 }}>Create documents using a full-featured rich text editor, insert answers from research agents, and export your document to multiple formats.</p>
              <Image src={'/assets/frontpage/feature-editor.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <Sparkle size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Agents</h4>
              <p className='muted' style={{ marginTop: 0 }}>Agents first understand your question, to pull in data from relevant publications, news articles and the web to then build an answer for you.</p>
              <Image src={'/assets/frontpage/feature-sources.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <Database size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Knowledge Library</h4>
              <p className='muted' style={{ marginTop: 0 }}>Upload your PDFs, DOCs, PPTs and TXT files for complete indexing so agents can run a semantic search on them for relevant, useful answers.</p>
              <Image src={'/assets/frontpage/feature-library.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <ListMagnifyingGlass size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Citations</h4>
              <p className='muted' style={{ marginTop: 0 }}>Agents display citations for source publications, news articles, websites, and images so you can easily use them in your writing.</p>
              <Image src={'/assets/frontpage/feature-citations.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <BoundingBox size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Templates</h4>
              <p className='muted' style={{ marginTop: 0 }}>Avoid writer's block by kicking off every writing project with one of 10+ pre-built templates that generate a thoughful outline for you.</p>
              <Image src={'/assets/frontpage/feature-templates.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <Export size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Export</h4>
              <p className='muted' style={{ marginTop: 0 }}>Quickly and easily export your writing in multiple formats including PDF and HTML, to easily share or publish your content.</p>
              <Image src={'/assets/frontpage/feature-export.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
        </Row>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 0 }}>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <Cube size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>LLMs</h4>
              <p className='muted' style={{ marginTop: 0 }}>Choose from a selection of most major open and closed source LLMs based on your preferences of speed, cost and data policies.</p>
              <Image src={'/assets/frontpage/feature-models.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
          <Col xs={11} sm={6} md={6} lg={5} xl={4} style={{ padding: 10 }}>
            <Card style={{ padding: isPageWide ? 20 : 15, minHeight: 300 }}>
              <Lock size={24} color={'var(--accent)'} />
              <h4 style={{ marginTop: 20 }}>Security</h4>
              <p className='muted' style={{ marginTop: 0 }}>Data security is core to the Terastack platform with RLS, namespace separation, JWT-based auth, and a choice of managed open-source LLM models.</p>
              <Image src={'/assets/frontpage/feature-security.png'} style={{ width: '100%', marginTop: 20, border: '6px solid #3D3E40', objectFit: 'cover' }} />
            </Card>
          </Col>
        </Row>

        {/* Pricing */}

        <Row ref={pricingRef} style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 40, paddingTop: 40 }}>
          <Col xs={11} sm={11} md={6} lg={6} xl={5} style={{ padding: isPageWide ? 20 : 10, textAlign: 'center', maxWidth: 900 }}>
            <h6 className='muted'>Pricing</h6>
            <h2 style={{ marginTop: 10 }}>Simple, transparent pricing</h2>
          </Col>
        </Row>

        <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 40 }}>
          <Col xs={11} sm={11} md={10} lg={10} xl={8}>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0 }}>
              {BILLING_PLANS.map((b, id) => {
                return (
                  <Col xs={11} sm={6} md={6} lg={6} xl={6} style={{ padding: 10 }}>
                    <Card style={{ padding: isPageWide ? 20 : 15, minHeight: isPageWide ? 360 : 200 }}>
                      <h6 style={{ marginTop: 0 }}>{b.name}</h6>
                      <p style={{ marginTop: 0 }}>{b.description}</p>
                      <h2 style={{ marginTop: 20 }}>{b.priceMonthly}</h2>
                      <p className='small muted' style={{ marginTop: -5 }}>per workspace per month</p>
                      <ul style={{ marginTop: 10 }}>
                        {b.benefits.map((bb, id) => {
                          return (
                            <li key={id}>{bb}</li>
                          )
                        })}
                      </ul>
                    </Card>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>

        {/* Security: RLS, Multi-tenant DB, Open-sourced models */}

        {/* Who we are */}
        {/* <h6 style={{ textAlign: 'center', marginTop: 200 }}>Team</h6>
        <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 60 }}>
          <Col xs={6} sm={6} md={4} lg={4} xl={4} style={{ padding: 20, textAlign: 'center' }}>
            <Image src={'/assets/headshots/amit.png'} style={{ width: 160 }} roundedCircle />
            <h4 style={{ marginTop: 20 }}>Amit Jardosh</h4>
            <p style={{ textAlign: 'center', marginTop: 20 }}>Amit is a 3X tech founder of VC-backed and bootstrapped startups in sports, health, and climate - from inception to acquisition. Before jumping into the world of startups, he managed Pricing and Yield for Yahoo's Display Ads organization. He holds a Ph.D in Computer Science from UC Santa Barbara.</p>
          </Col>
        </Row> */}

        {/* FAQs */}

        {/* <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 100 }}>
          <Col xs={11} sm={11} md={6} lg={6} xl={5} style={{ padding: isPageWide ? 20 : 10, textAlign: 'center', maxWidth: 900 }}>
            <h2 style={{ marginTop: 0, maxWidth: 600 }}>Frequently Asked Questions</h2>
          </Col>
        </Row> */}

        <Row style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginLeft: 0, marginRight: 0, marginTop: 160, marginBottom: 160 }}>
          <Col xs={11} sm={11} md={10} lg={10} xl={8}>
            <h2 style={{ textAlign: 'center' }}>Get started</h2>
            <Row style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 0, marginRight: 0, marginTop: 20 }}>
              <Col xs={12} sm={10} md={8} lg={6} xl={6} style={{ padding: 0, textAlign: 'center' }}>
                {/* <p className='large muted'>Join our waitlist so we can get you set up.</p> */}
                {/* <PopupButton id="DIwxC8Cf" className="btn btn-primary" style={{ marginTop: 24 }}>
                  Join waitlist
                </PopupButton> */}
                <div class="launchlist-widget" data-key-id="7dUfaA" data-height="160px" style={{ marginTop: 10 }}></div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Footer />

        <div style={{ position: 'fixed', bottom: 20, right: 20, height: 40, width: 40, padding: 10, cursor: 'pointer' }} onClick={() => window.scrollTo(0, 0)}><ArrowUp size={20} color='var(--text-primary)' /></div>

      </Col>
      {/* TODO: Social media */}
    </Row>
  )


}
